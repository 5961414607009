<template>
  <div class="header">
    <div class="header__search-mobil">
      <BaseIcon name="logo" :class="{ hidden: isInputVisible }" size="24px" color="#643791" />
      <p>{{ titlesMap(router.path) }}</p>
    </div>
<!--    <ElInput class="header__search-decktop" size="large" placeholder="Поиск">-->
<!--      <template #prefix>-->
<!--        <BaseIcon name="search" size="20px" color="#AEACBF" />-->
<!--      </template>-->
<!--    </ElInput>-->
    <div class="header__search-mobil">
      <q-icon class="mobil-icon" size="24px" name="search" @click="showInput" />
      <div class="input-container" :class="{ 'show-input': isInputVisible }">
        <input
          class="search-input"
          type="text"
          placeholder="Имя или телефон"
          id="searchInputRef"
          v-model="searchInput"
          @blur="hideInput(false)"
          v-show="isInputVisible"
          :class="{
            'show-input': isInputVisible,
            'placeholder-typing': isInputVisible,
          }"
        />
        <q-icon
          class="close-icon"
          size="24px"
          name="close"
          @click="hideInput(true)"
          v-show="searchInput"
        />
      </div>
    </div>

    <div class="header__active-panel">
      <ElButton
        class="header__button-active create-deal"
        @click="PopUpControl.callPopUp('CreateDeal')"
        text
      >
        <base-icon name="plus-circle" />
      </ElButton>
      <ElButton
        class="header__button-active notif-display"
        :class="{ hidden: isInputVisible }"
        text
      >
        <el-badge :hidden="!ToastStoreControl.$state.isBadge" is-dot class="item">
          <BaseIcon name="bell" />
        </el-badge>
        <notif-display />
      </ElButton>
      <UserMenu class="header-user-menu" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'
import { ToastStore } from '~/store/toastStore'

const PopUpControl = PopUpStore()
const ToastStoreControl = ToastStore()

const router = useRoute()

const searchInput = ref<string>()

const isInputVisible = ref(false)

const titlesMap = (path: string) => {
  let value: string
  const map: { [key: string]: string } = {
    deals: 'Сделки',
  }
  value = map[path.replace(/[^a-zA-Zа-яА-Я]/g, '')] ?? ''
  return value
}

const showInput = () => {
  isInputVisible.value = true
  setTimeout(() => {
    document.getElementById('searchInputRef')?.focus()
  }, 100)
}

const hideInput = (isClose?: boolean) => {
  if (isClose || !searchInput.value) {
    isInputVisible.value = false
    searchInput.value = ''
  } else {
    return
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  position: relative;
  z-index: 1;
  width: calc(100vw - 65px);

  &__search {
    &-decktop {
      --el-border-radius-base: 40px;
      width: 280px;
      margin: 0 auto;
    }

    &-mobil {
      display: none;
    }
  }
  &__active-panel {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__button-active {
    padding: 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header {
    justify-content: flex-end;
    gap: 80px;
    &__search {
      &-decktop {
        margin: 0;
      }
    }
  }
}
</style>
